import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/filedocument', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/filedocument/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`module/filedocument/download/${id}`,

            { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/filedocument', {
            status: 1,
            data: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/filedocument/${userData.id}`, {
            status: 1,
            name: userData.name,
            data: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`module/filedocument/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchDataCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/all/personal/documentcategory')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    fetchedocumentlist(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/keydoc')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumentlegal(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/legal')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumentfinancials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/financials')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumenthealth(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/health')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumentutilities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/utilities')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumentemprec(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/employmentrecords')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumenthome(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/homeapplications')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchedocumeninvestment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/documentslistview/investment')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
