<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2 mb-2" v-if="appData">
      <div v-for="(item, index) in appData" :key="'itemlist' + index">
        <h5 class="mt-2">{{ $t(item.title) }}</h5>
        <b-row>
          <b-col
            v-for="(itemx, indexx) in item.children"
            :key="'itemlistz' + indexx"
            cols="12"
            sm="12"
            md="4"
            class="mt-1 d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <b-card-body class="border border-primary rounded p-0">
              <b-link
                :to="{
                  name: itemx.name,
                }"
                class="font-weight-bold d-block text-wrap"
              >
                <b-media
                  no-body
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-media-aside>
                    <b-avatar rounded size="50" variant="light-primary">
                      <feather-icon
                        size="25"
                        class="mx-1 my-1"
                        :icon="itemx.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body
                    class="d-flex justify-content-between align-items-center px-2"
                  >
                    <h6>
                      {{ $t(itemx.title) }}
                    </h6>
                    <!-- <div class="badge bg-primary mx-1 text-white rounded-pill">
                      {{ countdoclist[itemx.namex] || 0 }}
                    </div> -->
                  </b-media-body>
                </b-media>
              </b-link>
            </b-card-body>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import StoreModule from "./StoreModule";
import { ref, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      countdoclist: [],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
  },

  data() {
    return {};
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "documents";
    const modulename = "documents";
    const modulenamesub = "documents";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    const appData = [
      {
        title: "navigation.otherkeydoc",
        children: [
        {
            title: "navigation.will",
            name: "documents-will",
            namex: "documents_will",
            icon: "FolderIcon",
          },
          {
            title: "navigation.poa",
            name: "documents-poa",
            namex: "documents_poa",
            icon: "FolderIcon",
          },
          {
            title: "navigation.guarantor",
            name: "documents-guarantor",
            namex: "documents_guarantor",
            icon: "FolderIcon",
          },
          {
            title: "navigation.rationcard",
            name: "documents-rationcard",
            namex: "documents_rationcard",
            icon: "CreditCardIcon",
          },
          {
            title: "navigation.drivinglicense",
            name: "documents-drivinglicense",
            namex: "documents_drivinglicense",
            icon: "TruckIcon",
          },
          {
            title: "navigation.voterid",
            name: "documents-voterid",
            namex: "documents_voterid",
            icon: "CreditCardIcon",
          },
          {
            title: "navigation.pancard",
            name: "documents-pancard",
            namex: "documents_pancard",
            icon: "CreditCardIcon",
          },
          {
            title: "navigation.aadharcard",
            name: "documents-aadharcard",
            namex: "documents_aadharcard",
            icon: "CreditCardIcon",
            },
          {
            title: "navigation.birthcertificate",
            name: "documents-birthcertificate",
            namex: "documents_birthcertificate",
            icon: "FileTextIcon",
          },
          {
            title: "navigation.passport",
            name: "documents-passport",
            namex: "documents_passport",
            icon: "BookIcon",
          },
          {
            title: "navigation.educationcertificate",
            name: "documents-educationcertificate",
            namex: "documents_educationcertificate",
            icon: "FileTextIcon",
          },
        //   {
        //     title: "navigation.propertydocuments",
        //     namex: "documents_propertydocuments",
        //     name: "documents-propertydocuments",
        //     icon: "FileTextIcon",
        //   },
        ],
      },

    ];
    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      store.dispatch(
        GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
        edittype == 0 ? formData : jx
      );
    };

    const countdoclist = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchedocumentlist")
      .then((response) => {
        countdoclist.value = response.data;
      });

    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      countdoclist,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.electricity-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
